import * as React from 'react';
import {StatesButton} from 'wix-ui-tpa/StatesButton';
import {withGlobalProps} from '../../providers/globalPropsProvider';
import {IPropsInjectedByViewerScript} from '../../types/app-types';
import {ISantaProps} from '@wix/native-components-infra/dist/src/types/types';
import addToCartButtonStylable from './AddToCartButton.st.css';
import classnames from 'classnames';
import {BUTTON_STATES} from 'wix-ui-tpa/dist/src/components/StatesButton/constants';

export enum DataHook {
  Root = 'add-to-cart-button-root',
  AddToCartButton = 'add-to-cart-button',
}

export interface IAddToCartProps {
  globals: IPropsInjectedByViewerScript;
}

@withGlobalProps
export class AddToCartApp extends React.Component<IAddToCartProps & ISantaProps> {
  constructor(props: IAddToCartProps & ISantaProps) {
    super(props);
    this.reportAppLoaded = this.reportAppLoaded.bind(this);
  }

  public componentDidMount(): void {
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded); // eslint-disable-line @typescript-eslint/unbound-method
  }

  private readonly handleClick = (): void => {
    this.props.globals.handleAddToCart();
  };

  public render(): JSX.Element {
    const {
      globals: {shouldDisableButton, buttonText},
      host: {dimensions},
    } = this.props;

    return (
      <div
        data-hook={DataHook.Root}
        style={{height: dimensions.height}}
        className={classnames({
          [addToCartButtonStylable.disabled]: shouldDisableButton,
        })}>
        <StatesButton
          state={BUTTON_STATES.IDLE}
          className={classnames(addToCartButtonStylable.addToCartButton, addToCartButtonStylable.strechToContainer)}
          onClick={this.handleClick}
          data-hook={DataHook.AddToCartButton}
          idleContent={buttonText}
          disabled={shouldDisableButton}
          fullWidth
        />
      </div>
    );
  }

  private reportAppLoaded() {
    /* istanbul ignore next: hard to test it */
    this.props.globals.onAppLoaded && this.props.globals.onAppLoaded();
  }
}
